import React from 'react';
import xhr from 'xhr';
import smoothscroll from 'smoothscroll-polyfill';
import { directCall } from '@nmx/utils/dist/utilities/frontend/Analytics/dtm_helper';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import Session from '@nmx/utils/dist/utilities/frontend/session';
// components
import NMCareerApplicationForm from './nm-career-application-form.component';
import NMDynamicGoogleMap from '../nm-dynamic-google-map.component';
import NMLocationSearchInput from '../nm_location_search_input.component';

smoothscroll.polyfill();
const locationInputLabel = 'City or Address';
const locationInputErrorMessage = 'Oops! Please enter location';

export default class NMCareerOfficeLocatorForm extends React.Component {
  location;

  constructor(props) {
    super(props);

    this.state = {
      locationChangeInputActive: false,
      locationLabelIsActive: false,
      locationLabel: locationInputLabel,
      locationInputHasError: false,
      lat: undefined,
      lng: undefined,
      address: undefined,
      graduation_year: undefined,
      military_status: undefined,
      militaryCandidate: undefined,
      work_status: undefined,
      found_us_via: undefined,
      referral_name: undefined,
      career_interest: undefined,
      initialOfficeData: undefined,
      officeData: undefined,
      currentZip: Session.get('careerInterestUserZip') || undefined,
      submitButtonEnabled: true,
      formSubmitted: false,
      checkedInput: undefined,
      selectedOfficeData: [],
      listPaneActive: true,
      mapPaneActive: false,
      mapPinOrRadioSelected: false,
      isSafari: /^((?!chrome|android).)*safari/i.test(typeof navigator !== 'undefined' ? navigator.userAgent : ''),
    };

    this.changeLocationInputRef = React.createRef();
    this.officeLocaterFormListRef = React.createRef();
  }

  componentWillMount = () => {
    this.setState({
      lat: Location.getQueryParam('lat'),
      lng: Location.getQueryParam('lng'),
      address: Location.getQueryParam('address'),
      graduation_year: Location.getQueryParam('graduation_year'),
      military_status: Location.getQueryParam('military_status'),
      militaryCandidate: Location.getQueryParam('military_candidate'),
      work_status: Location.getQueryParam('work_status'),
      found_us_via: Location.getQueryParam('found_us_via'),
      referral_name: Location.getQueryParam('referral_name'),
      career_interest: Location.getQueryParam('career_interest'),
    }, () => {
      if (this.state.lat && this.state.lng) {
        this.getOfficeData();
      }
    });
  }

  getUserZip = async () => {
    // clearing out any possible previous careerInterestUserZip value
    Session.set('careerInterestUserZip', '');
    if (window.google) {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = {
        lat: Number(this.state.lat),
        lng: Number(this.state.lng),
      };

      try {
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ latLng }, (res, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              resolve(res);
            } else {
              reject(status);
            }
          });
        });

        const locationInfo = results;
        for (let i = 0; i < locationInfo[0].address_components.length; i += 1) {
          if (locationInfo[0].address_components[i].types[0] === 'postal_code') {
            this.setState({ currentZip: locationInfo[0].address_components[i].long_name });
            // also set user zip to session storage
            Session.set('careerInterestUserZip', locationInfo[0].address_components[i].long_name);
          }
        }
      } catch (error) {
        console.error('Error occurred during geocoding:', error);
      }
    } else {
      setTimeout(() => {
        this.getUserZip();
      }, 200);
    }
  };

  componentDidMount = () => {
    this.setBackButtonFunctionality();
    this.getUserZip();
    this.disableSubmitButton();

    if (typeof window !== 'undefined' && this.state.isSafari) {
      // Use MutationObserver to detect class change on body el to detect if modal is open
      const observerConfig = {
        attributes: true,
        attributeFilter: ['class'],
        subtree: false,
      };

      // When a mutation is observed
      // eslint-disable-next-line func-names
      const observerCallback = function (mutationsList) {
        mutationsList.forEach((mutation) => {
          // If modal is open, set remove overflowY on scrolling office locator list to prevent Search modal from breaking (Safari)
          if (mutation.target.classList.contains('modal-is-open')) {
            document.getElementById('nm-career-office-locator-form-list').style.overflowY = 'hidden';
          } else {
            document.getElementById('nm-career-office-locator-form-list').style.overflowY = 'auto';
          }
        });
      };

      // Create an new observer
      const observer = new MutationObserver(observerCallback);

      // Start observing
      observer.observe(document.body, observerConfig);
    }
  }

  setBackButtonFunctionality = () => {
    // Sends user to previous page rather than previous set of query params,
    // if they change city
    window.history.pushState(null, null, window.location.href);
  }

  activateListPane = () => {
    this.setState({
      listPaneActive: true,
      mapPaneActive: false,
    });
    const checkedInput = document.querySelector('input[type=radio]:checked');
    // Scroll selected input into view after input list renders for mobile
    setTimeout(() => {
      this.scrollInputIntoView(checkedInput);
    }, 300);
  }

  activateMapPane = () => {
    this.setState({
      listPaneActive: false,
      mapPaneActive: true,
    });
  }

  showChangeLocationInput = () => {
    this.setState({ locationChangeInputActive: true });
    this.changeLocationInputRef.current.focus();
  }

  hideChangeLocationInput = () => {
    // close and clear change location input
    document.getElementById('nm-career-office-locator-form-location-input').value = '';
    this.setState({
      locationChangeInputActive: false,
      locationInputHasError: false,
      locationLabel: locationInputLabel,
      locationLabelIsActive: false,
    });
  }

  getOfficeData = () => {
    const officeDataUrl = `<%=searchApiBaseUrl%>/offices?lat=${this.state.lat}&lng=${this.state.lng}`;
    xhr({
      method: 'GET',
      uri: officeDataUrl,
    }, (err, resp, body) => {
      if (body) {
        const initialOfficeData = JSON.parse(body);
        let filteredOfficeData = [];

        // filter out duplicate offices
        for (let i = 0; i < initialOfficeData.length; i += 1) {
          if (initialOfficeData[i].isDistrictDirectorOffice === false) {
            filteredOfficeData.push(initialOfficeData[i]);
          }
        }

        // remove home office from array of displayed locations
        const networkOfficeBlocklist = ['136']; // we can also add any other problem NO's by adding them to this array if need be
        filteredOfficeData = filteredOfficeData.filter(item => !networkOfficeBlocklist.includes(item.noNum));
        this.setState({ officeData: filteredOfficeData });
      } else {
        console.error(`Failed to retrieve office data with error ${err}`);
      }
    });
  }

  // if a location is selected then submit form to search
  onLocationSelect = (location, event) => {
    // if user hit enter and the input was blank
    if (event.keyCode === 13 && !location.address) {
      this.setState({
        locationInputHasError: true,
        locationLabel: locationInputErrorMessage,
      });
    } else {
      this.setState({
        locationInputHasError: false,
        locationLabel: locationInputLabel,
      });
      this.resetLocation(location);
    }
    this.showLocationLabel();
  }

  resetLocation =(location) => {
    if (location) {
      // These states get passed into the search API call
      this.setState({
        address: location.address,
        lat: location.lat,
        lng: location.lng,
      });

      // Update query params with new address, lat, and lng
      Location.updateQueryParams({
        address: this.state.address,
        lat: this.state.lat,
        lng: this.state.lng,
      });
      this.trackAnalyticsFill('location', this.state.address);
      // reload page
      window.location.reload();
    }
  }

  trackAnalyticsFill = (actionType, actionValue) => {
    directCall('shared-call', {
      'action-type': actionType,
      'action-keys': 'nm-career-interest-form',
      'action-values': actionValue,
    });
  }

  showLocationLabel = () => {
    this.setState({ locationLabelIsActive: true });
  }

  refineDistanceInMiles = (dist) => {
    const refinedDistanceInMiles = (dist).toFixed(1);
    return refinedDistanceInMiles;
  }

  // Calculate user distance from office if distanceInMiles is undefined
  getDistanceFromUser = (lt, lg) => {
    const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
      // user location
      new window.google.maps.LatLng(this.state.lat, this.state.lng),
      // office location
      new window.google.maps.LatLng(lt, lg)
    );
    const computedDistanceInMiles = (distanceInMeters * 0.000621371192).toFixed(1);
    return computedDistanceInMiles;
  }

  scrollInputIntoView = (el) => {
    const labelElem = el.parentNode.parentNode.parentNode;
    const listElem = document.getElementById('nm-career-office-locator-form-list');
    listElem.scrollTop = labelElem.offsetTop;
  }

  mapInputSelect = (id) => {
    const elem = document.getElementById(id);
    elem.checked = true;
    // Grab the label and the list menu to set the scrollTop
    // so that the selected input scrolls into view
    this.scrollInputIntoView(elem);
    this.hideChangeLocationInput();
    this.setSelectedInputData(elem);
  }

  radioInputSelect = (event) => {
    this.hideChangeLocationInput();
    this.setSelectedInputData(event.target);
  }

  setSelectedInputData = (input) => {
    this.setState({
      checkedInput: input,
      mapPinOrRadioSelected: true,
    }, () => {
      // clear checked classes from checkbox containers
      const modifierClass = 'is-active';
      const radioContainers = document.querySelectorAll('.nm-career-office-locator-form__list-item-label');
      const radioContainerParent = document.querySelectorAll('.nm-career-office-locator-form__list-item-container');
      for (let i = 0; i < radioContainers.length; i += 1) {
        radioContainers[i].classList.remove(modifierClass);
        radioContainerParent[i].classList.remove(modifierClass);
      }
      // Add background class to checked input's grandparent container
      const checkedInputContainer = this.state.checkedInput.parentNode.parentNode;
      checkedInputContainer.classList.add(modifierClass);

      // add active class to container for mobile map pin select
      const checkedInputContainerParent = this.state.checkedInput.parentNode.parentNode.parentNode;
      checkedInputContainerParent.classList.add(modifierClass);

      const inputIndex = this.state.checkedInput.value;
      const inputOfficeData = this.state.officeData[inputIndex];
      this.setState({ selectedOfficeData: inputOfficeData });
      this.enableSubmitButton();
    });
  }

  disableSubmitButton = () => {
    this.setState({ submitButtonEnabled: true });
  }

  enableSubmitButton = () => {
    this.setState({ submitButtonEnabled: false });
  }

  // Callback that fires on step 3 of the flow
  // Reloads step 2 of the form instead of going back to the previous page
  resetLocatorForm = () => {
    this.setState({ formSubmitted: false });
    window.location.reload();
  }

  validateForm = (event) => {
    this.disableSubmitButton();
    event.preventDefault();
    event.stopPropagation();
    this.setState({ formSubmitted: true });
    window.scrollTo(0, 0);
  }

  preventEventBubbling = (event) => {
    event.preventDefault();
  }

  render = () => {
    // If user lands on /careers-apply without any query params present,
    // (meaning, they did not complete step 1 of the form),
    // redirect them to the careers page
    if (!this.state.career_interest) {
      window.location.href = '/careers';
    }

    const {
      locationChangeInputActive,
      locationLabelIsActive,
      locationInputHasError,
      locationLabel,
      officeData,
      currentZip,
      submitButtonEnabled,
      formSubmitted,
    } = this.state;

    if (!officeData) {
      return (
        <div className="general-content">
          <div className="loader" />
        </div>
      );
    }
    if (formSubmitted) {
      return (
        <NMCareerApplicationForm
          officeData={this.state.selectedOfficeData}
          userData={this.state}
          resetform={this.resetLocatorForm} />
      );
    }
    return (
      <div className='nm-career-office-locator-form'>
        <h4 className="nm-career-office-locator-form__step-header">
          <span className="nm-career-office-locator-form__step-header--bold">Step 2</span> of 3
        </h4>
        <h1 className="nm-career-office-locator-form__header">Choose a location that&#39;s perfect for you.</h1>
        <div className="nm-career-office-locator-form__change-location-container">
          <div className={`nm-career-office-locator-form__change-location-message
            ${!locationChangeInputActive && 'is-active'}`}>
            <p className='nm-career-office-locator-form__change-location-message-text'>
              <span className='nm-career-office-locator-form__change-location-message-text-block'>{officeData.length} offices within or near</span>
              <span className='nm-career-office-locator-form__change-location-message-text-block'> ZIP Code&#58; {currentZip}
                <button
                  className='nm-career-office-locator-form__change-location-button'
                  onClick={this.showChangeLocationInput}>
                  Change location
                </button>
              </span>
            </p>
          </div>
          <div className={`nm-career-office-locator-form__change-location-input-container
            ${locationChangeInputActive && 'is-active'} ${locationInputHasError && 'has-error'}`}>
            <label
              className="nm-career-office-locator-form__label"
              onClick={this.preventEventBubbling}
              role='presentation'>
              <span
                className={`nm-career-office-locator-form__label-text
                ${locationLabelIsActive && 'nm-career-office-locator-form__label-text--is-active'}
                ${locationInputHasError && 'nm-career-office-locator-form__label-text--has-error'}`}>
                {locationLabel}
              </span>
              <NMLocationSearchInput
                id='nm-career-office-locator-form-location-input'
                type='text'
                className='nm-career-office-locator-form__location-input'
                placeholder={`Search by ${locationInputLabel}`}
                onSelect={this.onLocationSelect}
                onKeyDown={this.showLocationLabel}
                dismissLocationInput={this.hideChangeLocationInput}
                hasDismissButton
                ref={this.changeLocationInputRef}
              />
            </label>
          </div>
        </div>
        <form
          id='career-office-locator-form'
          method='get'
          className='nm-career-office-locator-form__form'
          onSubmit={this.validateForm}>
          <div className='nm-career-office-locator-form__row'>
            <div className='nm-career-office-locator-form__panel-controls-row'>
              <div className='nm-career-office-locator-form__panel-controls-col'>
                <button
                  id='nm-career-office-locator-form-list-control-button'
                  type='button'
                  className={`nm-career-office-locator-form__panel-controls-button nm-career-office-locator-form__panel-controls-button--left ${this.state.listPaneActive && 'is-active-state'}`}
                  onClick={this.activateListPane}
                  ref={this.officeLocaterFormListRef}>
                  List
                </button>
              </div>
              <div className='nm-career-office-locator-form__panel-controls-col'>
                <button
                  id='nm-career-office-locator-form-map-control-button'
                  type='button'
                  className={`nm-career-office-locator-form__panel-controls-button nm-career-office-locator-form__panel-controls-button--right ${this.state.mapPaneActive && 'is-active-state'}`}
                  onClick={this.activateMapPane}>
                  Map
                </button>
              </div>
            </div>
            <div className={`nm-career-office-locator-form__map-container ${this.state.mapPaneActive && 'is-active-state'}`}>
              <NMDynamicGoogleMap
                data={officeData}
                mapselectcallback={this.mapInputSelect}
                inputclass='.nm-career-office-locator-form__list-item-radio' />
            </div>
            <div className={`nm-career-office-locator-form__list-container
              ${this.state.listPaneActive && 'is-active-state'}
              ${this.state.mapPinOrRadioSelected && 'location-selected-mobile'}`}>
              <div
                id='nm-career-office-locator-form-list'
                className={`nm-career-office-locator-form__list ${officeData.length === 0 && 'is-hidden'}`}>
                {Object.keys(officeData).map((key, index) => (
                  <div key={index} className='nm-career-office-locator-form__list-item-container'>
                    <label className='nm-career-office-locator-form__list-item-label'>
                      <div className='nm-career-office-locator-form__radio-col'>
                        <input
                          id={`office-location-input-${Number(key) + 1}`}
                          className='nm-career-office-locator-form__list-item-radio'
                          type="radio"
                          name="officeLocation"
                          value={key}
                          onClick={this.radioInputSelect} />
                      </div>
                      <div className='nm-career-office-locator-form__list-item-index-col'>
                        <span className='nm-career-office-locator-form__list-item-index'>{Number(key) + 1}.&nbsp;</span>
                      </div>
                      <div className='nm-career-office-locator-form__list-item-address-col'>
                        <span className='nm-career-office-locator-form__list-item-office-name'>
                          {(!officeData[key].title || officeData[key].title === 'TEMP TITLE')
                            ? officeData[key].city
                            : officeData[key].title}
                        </span>
                        <address className='nm-career-office-locator-form__list-item-address'>
                          <span className='nm-career-office-locator-form__list-item-address-line'>{officeData[key].street} {officeData[key].building} </span>
                          <span className='nm-career-office-locator-form__list-item-address-line'>{officeData[key].city}, {officeData[key].state} {officeData[key].zip}</span>
                        </address>
                        <a
                          href={officeData[key].websiteUrl}
                          className='nm-career-office-locator-form__list-item-link'
                          aria-label={`Visit the ${officeData[key].city} office website`}
                          target='_blank'>
                          Visit website
                        </a>
                      </div>
                      <div className='nm-career-office-locator-form__list-item-distance-col'>
                        <span className='nm-career-office-locator-form__list-item-distance'>
                          {officeData[key].distanceInMiles
                            ? this.refineDistanceInMiles(officeData[key].distanceInMiles)
                            : this.getDistanceFromUser(officeData[key].location.lat, officeData[key].location.lon)}&nbsp;mi
                        </span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              <div className='nm-career-office-locator-form__list-container-fade-overlay' />
              {officeData.length === 0
                && <div className='nm-career-office-locator-form__placeholder-list'>
                  <div className='nm-career-office-locator-form__placeholder-list-item'>
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar nm-career-office-locator-form__placeholder-list-item-bar--short' />
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar' />
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar' />
                  </div>
                  <div className='nm-career-office-locator-form__placeholder-list-item'>
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar nm-career-office-locator-form__placeholder-list-item-bar--short' />
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar' />
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar' />
                  </div>
                  <div className='nm-career-office-locator-form__placeholder-list-item'>
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar nm-career-office-locator-form__placeholder-list-item-bar--short' />
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar' />
                    <div className='nm-career-office-locator-form__placeholder-list-item-bar' />
                  </div>
                </div>}
            </div>
          </div>
          <button
            id='nm-career-office-locator-form-submit-button'
            type="submit"
            name="submit"
            value="Continue"
            className="nm-career-office-locator-form__button nmx-button is-yellow"
            onClick={this.validateForm}
            disabled={submitButtonEnabled}>
            Continue
          </button>
        </form>
      </div>
    );
  }
}
