export function isObject(object) {
  return object != null && typeof object === 'object' && Array.isArray(object) === false;
}

export function isFunction(object) {
  return object && Object.prototype.toString.call(object) === '[object Function]';
}

export function isDate(object) {
  return object && Object.prototype.toString.call(object) === '[object Date]';
}

export function isNumber(object) {
  return object && Object.prototype.toString.call(object) === '[object Number]';
}

export function isRegExp(object) {
  return object && Object.prototype.toString.call(object) === '[object RegExp]';
}

export function isString(object) {
  return object && Object.prototype.toString.call(object) === '[object String]';
}

export function isBoolean(object) {
  return object && Object.prototype.toString.call(object) === '[object Boolean]';
}

export function isArray(object) {
  return Array.isArray(object);
}

export function isUndefined(object) {
  return (typeof object === 'undefined');
}

export function isEmpty(object) {
  if (typeof object === 'undefined') {
    return true;
  }

  if (isFunction(object) || isNumber(object) || isBoolean(object) || isDate(object)) {
    return false;
  }

  if (isString(object)) {
    return object.replace(/\s+/gi, '').length <= 0;
  }

  if (object == null || object.length === 0) {
    return true;
  }

  if (isObject(object)) {
    return Object.keys(object).length === 0 && object.constructor === Object;
  }
  return false;
}

export function isPhoneNumber(string) {
  if (!isString(string)) {
    return false;
  }

  if (isEmpty(string)) {
    return false;
  }

  return /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(string);
}

export function isEmail(string) {
  if (!isString(string)) {
    return false;
  }

  if (isEmpty(string)) {
    return false;
  }
  // Email domain extensions must be a minimum of 2 chars
  try {
    if (string.substr(string.length - 2).includes('.')) {
      return false;
    }
  } catch (err) {
    console.error(`isEmail checking util errored with error ${err}`);
  }

  const regex = /^(?!')\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$(?!')/i;

  if (!regex.test(string)) {
    return false;
  }

  return true;
}

export function isZipCode(string) {
  if (!isString(string)) {
    return false;
  }

  if (isEmpty(string)) {
    return false;
  }

  if (!new RegExp(/^[0-9]{5}$/gi).test(string)) {
    return false;
  }

  return true;
}

export function isDateFormat(string, allowedToBeEmpty = true) {
  if (allowedToBeEmpty && isEmpty(string)) {
    return true;
  }

  if (!new RegExp(/^\d{1,2}\/\d{1,2}\/\d{4}$/).test(string)) {
    return false;
  }
  return true;
}

export function isAlphaNumeric(string, allowedToBeEmpty = true) {
  if (allowedToBeEmpty && isEmpty(string)) {
    return true;
  }

  if (!new RegExp(/^[a-z0-9]+$/i).test(string)) {
    return false;
  }
  return true;
}

// replace phone number with proper format
export function autoFormatPhone(str) {
  // sanitize the phone input and reformat to (xxx) xxx-xxxx
  const cleanNum = str.replace(/[^?0-9.]/g, '');
  return cleanNum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function isUrl(str) {
  // fragment locater
  // eslint-disable-next-line no-useless-escape
  const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  if (!regex.test(str)) {
    return false;
  }
  return true;
}

const STATE_ABBREVIATIONS = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
export function isStateAbbreviation(string) {
  if (!isString(string)) {
    return false;
  }

  if (string.length !== 2) {
    return false;
  }

  return (STATE_ABBREVIATIONS.indexOf(string.toUpperCase()) > -1);
}
