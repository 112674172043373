import React from 'react';
import Log from '@nmx/utils/dist/utilities/frontend/frontend-logger';
import { newRelicJSError } from '../analytics/new_relic_helper';
import fixRecaptchaWindow from '../helpers/recaptchaPositioning';

// use this recaptcha component if there is ONLY 1 recaptcha on the page
export default class NMRecaptcha extends React.Component {
  recaptchaElm;

  componentDidMount = () => {
    this.renderRecaptcha();
  }

  renderRecaptcha = () => {
    try {
      if (window.grecaptcha && window.grecaptcha.render) {
        window.grecaptcha.render(this.recaptchaElm, {
          sitekey: this.props.sitekey,
          size: this.props.size,
          callback: this.props.callback,
        });
        fixRecaptchaWindow();
      } else {
        setTimeout(this.renderRecaptcha, 50);
      }
    } catch (error) {
      // currently, we will always get at least one newRelic error while trying to load recaptcha,
      // because this will keep trying until it succeeds. So, we'll need to figure out a
      // solid react way of getting this to render once, without errors.
      newRelicJSError(`Error rendering recaptcha on ${this.props.formLocation}.`, error);
      Log(`Error rendering recaptcha on ${this.props.formLocation}.`, 'error');
    }
  }

  render() {
    return (
      <div ref={(elm) => { this.recaptchaElm = elm; }} style={ {
        zIndex: 1000,
        position: 'relative',
      } } />
    );
  }
}
