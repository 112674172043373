// eslint-disable-next-line no-unused-vars
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default class NMScrollToAnchorButton extends React.Component {
  nmxoScroll = (event) => {
    event.preventDefault();
    const id = this.props.nmxltarget.substring(1);
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  render() {
    const {
      modifier,
      buttonid,
      val,
      label,
    } = this.props;
    return (
      <div className='nm-scroll-to-anchor-button'>
        <button
          id={buttonid}
          className={`nm-scroll-to-anchor-button__button nmx-button ${modifier}`}
          href='/'
          aria-label={label}
          onClick={this.nmxoScroll}>
          {val}
        </button>
      </div>
    );
  }
}
