import React from 'react';

export default class NMStaticGoogleMapPin extends React.Component {
  render = () => {
    const { latitude, longitude, zoom } = this.props;
    return (
      <a href={`http://maps.google.com/maps?q=loc:${latitude},${longitude}`} target='_blank' rel='nofollow noopener' aria-label='View map in Google Maps'>
        <picture>
          <source srcSet={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=300x170&markers=${latitude},${longitude}&maptype=roadmap&zoom=${zoom}&key=<%=googlePlacesApiKey%>`} media='(max-width: 640px)' />
          <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=500x300&markers=${latitude},${longitude}&maptype=roadmap&zoom=${zoom}&key=<%=googlePlacesApiKey%>`} alt='Google Map' />
        </picture>
      </a>
    );
  }
}
