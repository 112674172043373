/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
import React from 'react';
import { sharedCall } from '@nmx/utils/dist/utilities/frontend/Analytics/dtm_helper';
import AjaxForm from '../../services/ajaxForm.service';
import { isEmail, isPhoneNumber, autoFormatPhone, isUrl, isDateFormat } from '../../utils/checking.util';
import { newRelicJSError } from '../../analytics/new_relic_helper';
// eslint-disable-next-line no-unused-vars
import NMStaticGoogleMapPin from '../nm-static-google-map-pin.component';
// eslint-disable-next-line no-unused-vars
import NMFormConfirmation from './nm-form-confirmation.component';
// eslint-disable-next-line no-unused-vars
import NMRecaptcha from '../nm_recaptcha.component';

// Personal info labels
const firstNameLabel = 'First name';
const lastNameLabel = 'Last name';
const emailLabel = 'Email address';
const phoneLabel = 'Phone number';
const ethnicityLabel = 'Ethnicity'; // (Optional)
const veteranStatusLabel = 'Veteran status'; // (Optional)
const separationDateLabel = 'Separation date';
const terminalLeaveDateLabel = 'Terminal Leave date (optional)'; // (optional)
const educationLabel = 'Highest level completed';
const linkedInUrlLabel = 'LinkedIn profile URL'; // (optional)
const resumeUploadLabel = 'Upload resume (PDF, MS Word, .txt)*';
const coverLetterUploadLabel = 'Upload cover letter (optional)'; // (optional)
const fileUploadButtonLabel = 'CHOOSE FILE';
let resumeUploadButtonLabel = fileUploadButtonLabel;
let coverLetterUploadButtonLabel = fileUploadButtonLabel;
const resume = 'resume';
const coverLetter = 'coverLetter';

// placeholders when they differ from label
const ethnicityPlaceholder = `${ethnicityLabel} (Optional)`;
const veteranStatusPlaceholder = 'Are you a veteran? (optional)';
const linkedInUrlPlaceholder = `Add ${linkedInUrlLabel} (optional)`;

// Error labels
const prependErrorMessage = 'Oops! Enter';
const firstNameErrorMessage = `${prependErrorMessage} first name.`;
const lastNameErrorMessage = `${prependErrorMessage} last name.`;
const emailErrorMessageEmpty = `${prependErrorMessage} email.`;
const emailErrorMessageFormat = 'Hmm... not a valid email format.';
let emailErrorMessage = emailErrorMessageEmpty;
const phoneErrorMessageEmpty = `${prependErrorMessage} phone number.`;
const phoneErrorMessageFormat = 'Hmm... not a valid phone format.';
let phoneErrorMessage = phoneErrorMessageEmpty;
const dateErrorMessage = 'Oops! Enter a date.';
const educationErrorMessage = 'Oops! Pick an option.';
const fileUploadErrorMessageFormat = 'Sorry, wrong file format.';
const fileUploadErrorMessageSize = 'Sorry, file is too large.';
const resumeUploadErrorMessageEmpty = 'Oops! Forgot to upload resume.';
let resumeUploadErrorMessage = resumeUploadErrorMessageEmpty;
let coverLetterUploadErrorMessage = '';
const linkedInUrlErrorMessage = 'Hmm… not a valid URL.';

const ethnicityArray = [
  'Black or African American',
  'Caucasian',
  'East Asian',
  'South Asian',
  'Hispanic or Latino',
  'Middle Eastern',
  'Multi-Ethnic',
  'Native American/First Nations',
  'North African',
  'Pacific Islander',
  'Other',
];

const educationArray = [
  'High school or GED',
  'Associate degree',
  "Bachelor's degree",
  "Master's degree",
  'Doctoral degree',
];

export default class NMCareerApplicationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '', // required
      lastName: '', // required
      email: '', // required
      phone: '', // required
      ethnicity: '',
      veteranStatus: '',
      separationDate: '',
      terminalLeaveDate: '',
      education: '', // required
      linkedInUrl: '',
      resumeUpload: undefined, // required
      coverLetterUpload: undefined,
      showSuccessConfirmation: false,
      isSubmitting: false,
      isShowingError: false,
      formHasErrors: false,
      isSafari: false,
      errors: {
        firstNameHasError: false,
        lastNameHasError: false,
        emailHasError: false,
        phoneHasError: false,
        separationDateHasError: false,
        terminalLeaveDateHasError: false,
        educationHasError: false,
        resumeUploadHasError: false,
        coverLetterUploadHasError: false,
        linkedInUrlHasError: false,
      },
    };
  }

  // Checks specifically for Safari browser
  safariCheck = () => {
    if ((navigator.userAgent.indexOf('Safari') !== -1) && (navigator.userAgent.indexOf('Chrome') === -1)) {
      this.setState({ isSafari: true });
    }
  }

  componentDidMount = () => {
    this.setBackButtonFunctionality();
    this.addFileUploadListener('career-applicant-resume-upload-button', 'career-applicant-resume-upload');
    this.addFileUploadListener('career-applicant-cover-letter-upload-button', 'career-applicant-cover-letter-upload');
    this.addFileUploadCloseListener('resume-reset-button');
    this.addFileUploadCloseListener('cover-letter-reset-button');
    this.safariCheck();
  }

  // Event Handlers ........................................................
  addFileUploadListener = (buttonID, inputID) => {
    const uploadButton = document.getElementById(buttonID);
    const fileInput = document.getElementById(inputID);
    uploadButton.addEventListener('keydown', (e) => {
      if ((e.keyCode === 32) || (e.keyCode === 13)) {
        fileInput.click();
      }
    });
  }

  addFileUploadCloseListener = (buttonID) => {
    const closeButton = document.getElementById(buttonID);
    closeButton.addEventListener('keydown', (e) => {
      if ((e.keyCode === 32) || (e.keyCode === 13)) {
        this.resetFileUpload();
      }
    });
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
    if (name === 'phone') {
      const formattedValue = autoFormatPhone(value);
      this.setState({ [name]: formattedValue });
    }
  }

  handleFileUpload = (event) => {
    event.persist();
    const { name } = event.target;

    this.setState({ [name]: event.target.files[0] }, () => {
      switch (name) {
      case 'resumeUpload':
        resumeUploadButtonLabel = event.target.files[0].name;
        this.setState({ resumeUploadHasError: this.validateFile(this.state.resumeUpload, resume) });
        break;
      case 'coverLetterUpload':
        coverLetterUploadButtonLabel = event.target.files[0].name;
        this.setState({ coverLetterUploadHasError: this.validateFile(this.state.coverLetterUpload, coverLetter) });
        break;
      default:
            // do nothing
      }
      return false;
    });
  }

  resetFileUpload = (event) => {
    const { id } = event.target;
    switch (id) {
    case 'resume-reset-button':
      this.setState({
        resumeUpload: undefined,
        resumeUploadHasError: false,
      });
      resumeUploadButtonLabel = fileUploadButtonLabel;
      document.getElementById('career-applicant-resume-upload').value = null;
      break;
    case 'cover-letter-reset-button':
      this.setState({
        coverLetterUpload: undefined,
        coverLetterUploadHasError: false,
      });
      coverLetterUploadButtonLabel = fileUploadButtonLabel;
      document.getElementById('career-applicant-cover-letter-upload').value = null;
      break;
    default:
        // do nothing
    }
  }

  // Other functions ........................................................
  refineDistanceInMiles = dist => (dist).toFixed(1)

  setBackButtonFunctionality = () => {
    // Stop user from going back, and instead reload step 2 of the flow
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.go(1);
      this.props.resetform();
    };
  }

  logError = (message, error) => {
    newRelicJSError(message, error);
    console.error(message, error);
  }

  errorCheck = (errField) => {
    let res = false;
    Object.keys(errField).forEach((key) => {
      const value = errField[key];
      if (value === true) {
        res = true;
      }
    });
    return res;
  }

  recaptchaCallback = (recaptchaResponseToken) => {
    this.setState({ isSubmitting: true });
    this.submitForm(recaptchaResponseToken);
  }

  // Validators ........................................................
  validateEmail = (userInput) => {
    // if empty
    if (!userInput) {
      emailErrorMessage = emailErrorMessageEmpty;
      return true;
    }
    // if wrong format
    if (!isEmail(userInput)) {
      emailErrorMessage = emailErrorMessageFormat;
      return true;
    }
    return false;
  }

  validatePhone = (userInput) => {
    // if empty
    if (!userInput) {
      phoneErrorMessage = phoneErrorMessageEmpty;
      return true;
    }
    // if wrong format
    if (!isPhoneNumber(userInput) || userInput.length !== 14) {
      phoneErrorMessage = phoneErrorMessageFormat;
      return true;
    }
    return false;
  }

  formatPhoneForSubmission = phone => phone.replace(/[^0-9]/g, '')

  validateUrl = (userInput) => {
    if (!userInput) {
      return false;
    }
    // if invalid format
    if (!isUrl(userInput)) {
      return true;
    }
    return false;
  }

  validateDate = (input) => {
    let res = false;
    // Regex for YYYY-MM-DD format, which is what the date picker will return
    const dateRegex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    // Input is allowed to be empty
    if (!input) {
      return true; // true is good
    }
    if (dateRegex.test(input)) {
      res = true; // true is good
    }
    return res; // bad date if response if false
  }

  validateSeparationDate = (userInput) => {
    const isValidDate = this.validateDate(userInput);
    const isValidDateFormat = isDateFormat(userInput);
    // required when militaryCandidate is true, but is empty
    if (!userInput && this.props.userData.militaryCandidate) {
      return true; // true is bad
    }
    // Does not pass either date formatting standards (e.g. 2021-05-06 or 5/6/2021)
    // This check is necessary because not all versions of safari support date inputs,
    // so we need to validate against raw text inputs for dates as well.
    if (!isValidDate && !isValidDateFormat) {
      return true; // true is bad
    }

    return false; // false is good
  }

  validateTerminalLeaveDate = (userInput) => {
    const isValidDate = this.validateDate(userInput);
    const isValidDateFormat = isDateFormat(userInput);
    // not required, and allowed to be empty
    if (!userInput) {
      return false; // false is good, no errors
    }
    // Does not pass either date formatting standards (e.g. 2021-05-06 or 5/6/2021)
    // This check is necessary because not all versions of safari support date inputs,
    // so we need to validate against raw text inputs for dates as well.
    if (!isValidDate && !isValidDateFormat) {
      return true; // true is bad
    }

    return false; // false is good
  }

  validateFile = (f, name) => {
    // if empty
    if (!f) {
      if (name === resume) {
        resumeUploadErrorMessage = resumeUploadErrorMessageEmpty;
        return true;
      }
    } else {
      const { type, size } = f;
      // Checking file against permitted MIME types.
      switch (type) {
      case 'text/plain': // text
      case 'text/richtext': // rich text format
      case 'text/html': // html
      case 'application/pdf': // pdf
      case 'application/msword': // .doc
      case 'application/vnd.oasis.opendocument.text': // ODT
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
        break;
      default:
        if (name === resume) {
          resumeUploadErrorMessage = fileUploadErrorMessageFormat;
        }
        if (name === coverLetter) {
          coverLetterUploadErrorMessage = fileUploadErrorMessageFormat;
        }
        return true;
      }
      // File size in Bytes, 3MB limit
      if (size > 3145728) {
        if (name === resume) {
          resumeUploadErrorMessage = fileUploadErrorMessageSize;
        }
        if (name === coverLetter) {
          coverLetterUploadErrorMessage = fileUploadErrorMessageSize;
        }
        return true;
      }
    }
    return false;
  }

  validateInputs = () => {
    const errors = {
      firstNameHasError: !this.state.firstName,
      lastNameHasError: !this.state.lastName,
      emailHasError: this.validateEmail(this.state.email),
      phoneHasError: this.validatePhone(this.state.phone),
      separationDateHasError: this.validateSeparationDate(this.state.separationDate),
      terminalLeaveDateHasError: this.validateTerminalLeaveDate(this.state.terminalLeaveDate),
      educationHasError: !this.state.education,
      linkedInUrlHasError: this.validateUrl(this.state.linkedInUrl),
      resumeUploadHasError: this.validateFile(this.state.resumeUpload, resume),
      coverLetterUploadHasError: this.validateFile(this.state.coverLetterUpload, coverLetter),
    };
    this.setState({ errors });
    return errors;
  }

  validateForm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ formHasErrors: this.errorCheck(this.validateInputs()) }, () => {
      // If the form has no errors, try and submit
      if (!this.state.formHasErrors) {
        try {
          window.grecaptcha.execute();
        } catch (error) {
          this.logError('Error executing recaptcha on career application form.', error);
          this.setState({
            isSubmitting: false,
            isShowingError: true,
          });
        }
      } else {
        // validation errors on the form, need to reset recaptcha
        try {
          window.grecaptcha.reset();
        } catch (error) {
          this.logError('Error resetting recaptcha on career application form.', error);
          this.setState({
            isSubmitting: false,
            isShowingError: true,
          });
        }
      }
    });
  }

  submitForm = (recaptchaResponseToken) => {
    const ajaxForm = new AjaxForm(this.formEl);

    // USER DATA FROM STEP 1
    ajaxForm.addField('careerInterest', this.props.userData.career_interest); // required
    ajaxForm.addField('militaryStatus', this.props.userData.military_status);
    ajaxForm.addField('militaryCandidate', this.props.userData.militaryCandidate);
    ajaxForm.addField('workStatus', this.props.userData.work_status); // workStatus required if careerInterest is financial professional
    ajaxForm.addField('graduationYear', this.props.userData.graduation_year);
    ajaxForm.addField('howDidYouFindUs', this.props.userData.found_us_via);
    ajaxForm.addField('referralName', this.props.userData.referral_name); // required if howDidYouFindUs is Advisor referral, Approached by a recruiter, Family, or friend or acquaintance

    // OFFICE DATA FROM STEP 2
    ajaxForm.addField('noNumber', this.props.officeData.noNum); // required
    ajaxForm.addField('dnoNumber', this.props.officeData.dnoNum);

    // USER DATA FROM STEP 3
    ajaxForm.addField('firstName', this.state.firstName); // required
    ajaxForm.addField('lastName', this.state.lastName); // required
    ajaxForm.addField('email', this.state.email); // required
    ajaxForm.addField('phone', this.formatPhoneForSubmission(this.state.phone)); // required
    ajaxForm.addField('zip', this.props.userData.currentZip); // required
    ajaxForm.addField('ethnicity', this.state.ethnicity || 'Unknown');
    ajaxForm.addField('veteran_status', this.state.veteranStatus || 'U');
    ajaxForm.addField('separationDate', this.state.separationDate);
    ajaxForm.addField('terminalLeaveDate', this.state.terminalLeaveDate);
    ajaxForm.addField('education', this.state.education); // required
    ajaxForm.addField('resume-upload', this.state.resumeUpload); // required
    ajaxForm.addField('cover-letter-upload', this.state.coverLetterUpload || '');
    ajaxForm.addField('linkedInUrl', this.state.linkedInUrl);
    ajaxForm.addField('customTextFieldFlag', 'customText28');
    ajaxForm.addField('recaptchaResponseToken', recaptchaResponseToken); // required

    ajaxForm
      .promiseSubmit()
      .then(() => {
        this.setState({ showSuccessConfirmation: true });
        window.scrollTo(0, 0);
        // Do we still want this shared call function to fire?
        sharedCall('career-application-form-submit');
        // Need to confirm if this is google analytics, or the deprecated floodlights or googleTagManager
        window.gtag('event', 'send', {
          event_category: 'Forms',
          event_action: 'Successful Submission',
          event_label: 'Career Application Form Submit',
        });
      })
      .catch((error) => {
        this.setState({ isShowingError: true });
        this.logError('Error submitting office bullhorn form', error);
        // Do we still want this shared call function to fire?
        sharedCall('career-application-form-submit-failure');
        // Need to confirm if this is google analytics, or the deprecated floodlights or googleTagManager
        window.gtag('event', 'send', {
          event_category: 'Forms',
          event_action: 'Unsuccessful Submission',
          event_label: 'Career App Form Submit Failure',
        });
      }).then(() => {
        this.setState({ isSubmitting: false });
      });
  }

  render = () => {
    const { officeData } = this.props;

    const {
      firstName,
      lastName,
      email,
      phone,
      ethnicity,
      veteranStatus,
      separationDate,
      terminalLeaveDate,
      education,
      linkedInUrl,
      resumeUpload,
      coverLetterUpload,
      showSuccessConfirmation,
      isSafari,
    } = this.state;
    const {
      firstNameHasError,
      lastNameHasError,
      emailHasError,
      phoneHasError,
      separationDateHasError,
      terminalLeaveDateHasError,
      educationHasError,
      linkedInUrlHasError,
      resumeUploadHasError,
      coverLetterUploadHasError,
    } = this.state.errors;

    // redirect user back to careers page if user zip is undefined
    if (!this.props.userData.currentZip) {
      window.location.href = '/careers';
    }

    if (showSuccessConfirmation) {
      return (
        <div className='nm-career-application-form__confirmation-section'>
          <div className='nm-career-application-form__confirmation-row'>
            <div className='nm-career-application-form__intro-icon-container'>
              <img
                className='nm-career-application-form__intro-icon'
                src='/assets/images/thumbs.svg'
                alt='' />
            </div>
            <h1 className='nm-career-application-form__header'>Got it&#33; Thanks for applying,
              <span className='nm-career-application-form__header-block'> {firstName}.</span></h1>
            <p className='nm-career-application-form__text'>
              The&nbsp;
              {(!officeData.title || officeData.title === 'TEMP TITLE')
                ? officeData.city
                : officeData.title}
              &nbsp;office will take a look at everything you submitted and will get in touch with you soon.</p>
          </div>
          <div className='nm-career-application-form__intro-arrow' />
          <div className='nm-career-application-form__confirmation-location-section'>
            <div className='nm-career-application-form__confirmation-location-row'>
              <div className='nm-career-application-form__confirmation-map-container'>
                <NMStaticGoogleMapPin
                  latitude={officeData.location.lat}
                  longitude={officeData.location.lon}
                  zoom='14'
                />
              </div>
              <div className='nm-career-application-form__confirmation-info-container'>
                <address className='nm-career-application-form__confirmation-address'>
                  <span className='nm-career-application-form__confirmation-address-line'>{officeData.street} {officeData.building} </span>
                  <span className='nm-career-application-form__confirmation-address-line'>{officeData.city}, {officeData.state} {officeData.zip}</span>
                </address>
                <a
                  href={officeData.websiteUrl}
                  className='nm-career-application-form__list-item-link'
                  aria-label={`Visit the ${officeData.city} office website`}
                  target='_blank'>
                  Visit website
                </a>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&origin=${this.props.userData.lat},${this.props.userData.lng}&destination=${officeData.location.lat},${officeData.location.lon}`}
                  className='nm-career-application-form__list-item-link'
                  aria-label={`Get directions to the ${officeData.city} office.`}
                  target='_blank'>
                  Directions
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.isShowingError) {
      return (
        <div className='nm-career-application-form'>
          <NMFormConfirmation
            header1='Unable to submit form.'
            text1='Please try again later.' />
        </div>
      );
    }

    if (this.state.isSubmitting) {
      return (
        <div className="general-content">
          <div className="loader" />
        </div>
      );
    }

    return (
      <div className='nm-career-application-form'>
        <h4 className='nm-career-application-form__step-header'>
          <span className='nm-career-application-form__step-header--bold'>Step 3</span> of 3
        </h4>
        <h1 className='nm-career-application-form__header'>Just a few more details and you&#39;ll be all set.</h1>
        <p className='nm-career-application-form__text'>You&#39;re applying to the&nbsp;
          {(!officeData.title || officeData.title === 'TEMP TITLE')
            ? officeData.city
            : officeData.title}
            &nbsp;office.</p>
        <form
          id='nm-career-application-form'
          className='nm-career-application-form__form'
          method='POST'
          onSubmit={this.validateForm}
          action='<%=bullhornFieldOfficesSubmissionUrlV2%>'
          ref={(el) => {
            if (el) {
              this.formEl = el;
            }
          }}>
          <fieldset className='nm-career-application-form__group'>
            <legend className='nm-career-application-form__group-label'>Personal information</legend>
            <div className='nm-career-application-form__row'>
              <label
                className={`nm-career-application-form__input-label ${firstNameHasError && 'has-error'}`}>
                <span className='assistive-text'>{firstNameLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${firstName && 'is-active'}`}
                  aria-hidden='true'>
                  {!firstNameHasError
                    ? `${firstNameLabel}*`
                    : firstNameErrorMessage}
                </span>
                <input
                  id='career-applicant-first-name'
                  className='nm-career-application-form__input'
                  name='firstName'
                  placeholder={`${firstNameLabel}*`}
                  type='text'
                  aria-required='true'
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange} />
              </label>
              <label
                className={`nm-career-application-form__input-label ${lastNameHasError && 'has-error'}`}>
                <span className='assistive-text'>{lastNameLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${lastName && 'is-active'}`}
                  aria-hidden='true'>
                  {!lastNameHasError
                    ? `${lastNameLabel}*`
                    : lastNameErrorMessage}
                </span>
                <input
                  id='career-applicant-last-name'
                  className='nm-career-application-form__input'
                  name='lastName'
                  placeholder={`${lastNameLabel}*`}
                  type='text'
                  aria-required='true'
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  onBlur={this.handleInputChange} />
              </label>
              <label
                className={`nm-career-application-form__input-label ${emailHasError && 'has-error'}`}>
                <span className='assistive-text'>{emailLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${email && 'is-active'}`}
                  aria-hidden='true'>
                  {!emailHasError
                    ? `${emailLabel}*`
                    : emailErrorMessage}
                </span>
                <input
                  id='career-applicant-email'
                  className='nm-career-application-form__input'
                  name='email'
                  placeholder={`${emailLabel}*`}
                  type='text'
                  aria-required='true'
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  onBlur={this.handleInputChange} />
              </label>
              <label
                className={`nm-career-application-form__input-label ${phoneHasError && 'has-error'}`}>
                <span className='assistive-text'>{phoneLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${phone && 'is-active'}`}
                  aria-hidden='true'>
                  {!phoneHasError
                    ? `${phoneLabel}*`
                    : phoneErrorMessage}
                </span>
                <input
                  id='career-applicant-phone'
                  className='nm-career-application-form__input'
                  name='phone'
                  placeholder={`${phoneLabel}*`}
                  value={phone}
                  type='text'
                  aria-required='true'
                  maxLength='14'
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  onBlur={this.handleInputChange} />
              </label>
              <label
                className='nm-career-application-form__input-label nm-career-application-form__input-label--for-dropdown'>
                <span className='assistive-text'>{ethnicityLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${ethnicity && 'is-active'}`}
                  aria-hidden='true'>
                  {ethnicityLabel}
                </span>
                <select
                  id='career-applicant-ethnicity'
                  className='nm-career-application-form__input nm-career-application-form__input--is-dropdown'
                  name='ethnicity'
                  onBlur={this.handleInputChange}
                  onChange={this.handleInputChange}>
                  <option value='' aria-hidden='true'>{ethnicityPlaceholder}</option>
                  {ethnicityArray.map((item, index) => <option value={item} key={index}>{item}</option>)}
                </select>
              </label>
              <label
                className='nm-career-application-form__input-label nm-career-application-form__input-label--for-dropdown'>
                <span className='assistive-text'>{veteranStatusLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${veteranStatus && 'is-active'}`}
                  aria-hidden='true'>
                  {veteranStatusLabel}
                </span>
                <select
                  id='career-applicant-veteran-status'
                  className='nm-career-application-form__input nm-career-application-form__input--is-dropdown'
                  name='veteranStatus'
                  onBlur={this.handleInputChange}
                  onChange={this.handleInputChange}>
                  <option value=''>{veteranStatusPlaceholder}</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </select>
              </label>
              {/* Only show separationDate and terminalLeaveDate for military candidates */}
              {this.props.userData.militaryCandidate
                && <span>
                  <label
                    className={`nm-career-application-form__input-label ${separationDateHasError && 'has-error'}`}>
                    <span className='assistive-text'>{separationDateLabel}</span>
                    <span
                      className='nm-career-application-form__input-label-text is-active'
                      aria-hidden='true'>
                      {!separationDateHasError
                        ? `${separationDateLabel}*`
                        : dateErrorMessage}
                    </span>
                    <input
                      id='career-applicant-separation-date'
                      className={`nm-career-application-form__input nm-career-application-form__input--is-date-picker ${isSafari && 'is-safari'}`}
                      name='separationDate'
                      value={separationDate}
                      type='date'
                      aria-required='true'
                      onBlur={this.handleInputChange}
                      onChange={this.handleInputChange}
                    />
                  </label>
                  <label
                    className={`nm-career-application-form__input-label ${terminalLeaveDateHasError && 'has-error'}`}>
                    <span className='assistive-text'>{terminalLeaveDateLabel}</span>
                    <span
                      className='nm-career-application-form__input-label-text is-active'
                      aria-hidden='true'>
                      {!terminalLeaveDateHasError
                        ? `${terminalLeaveDateLabel}*`
                        : dateErrorMessage}
                    </span>
                    <input
                      id='career-applicant-terminal-leave-date'
                      className={`nm-career-application-form__input nm-career-application-form__input--is-date-picker ${isSafari && 'is-safari'}`}
                      name='terminalLeaveDate'
                      value={terminalLeaveDate}
                      type='date'
                      onBlur={this.handleInputChange}
                      onChange={this.handleInputChange}
                    />
                  </label>
                </span>}
            </div>
          </fieldset>
          <fieldset className='nm-career-application-form__group'>
            <legend className='nm-career-application-form__group-label'>Education</legend>
            <div className='nm-career-application-form__row'>
              <label
                className={`nm-career-application-form__input-label nm-career-application-form__input-label--for-dropdown ${educationHasError && 'has-error'}`}>
                <span className='assistive-text'>{educationLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${education && 'is-active'}`}
                  aria-hidden='true'>
                  {!educationHasError
                    ? `${educationLabel}*`
                    : educationErrorMessage}
                </span>
                <select
                  id='career-applicant-education'
                  className='nm-career-application-form__input nm-career-application-form__input--is-dropdown'
                  name='education'
                  onBlur={this.handleInputChange}
                  onChange={this.handleInputChange}>
                  <option value=''>{`${educationLabel}*`}</option>
                  {educationArray.map((item, index) => <option value={item} key={index}>{item}</option>)}
                </select>
              </label>
            </div>
          </fieldset>
          <fieldset className='nm-career-application-form__group'>
            <legend className='nm-career-application-form__group-label'>Experience</legend>
            <div className='nm-career-application-form__row'>
              <div className='nm-career-application-form__col'>
                <span className={`nm-career-application-form__file-input-label-text
                ${resumeUpload && 'is-active'}
                ${resumeUploadHasError && 'has-error'}`}>
                  {!resumeUploadHasError
                    ? resumeUploadLabel
                    : resumeUploadErrorMessage}
                </span>
                <label
                  id='career-applicant-resume-upload-label'
                  className={`nm-career-application-form__file-input-label ${resumeUploadHasError && 'has-error'}`}>
                  <span
                    id='career-applicant-resume-upload-button'
                    role='button'
                    aria-controls='career-applicant-resume-upload'
                    tabIndex='0'>
                    {resumeUploadButtonLabel}
                  </span>
                  <input
                    id='career-applicant-resume-upload'
                    className='nm-career-application-form__file-input'
                    name='resumeUpload'
                    type='file'
                    onChange={this.handleFileUpload} />
                  <button
                    id='resume-reset-button'
                    type='button'
                    className={`nm-career-application-form__reset-button ${resumeUpload && 'is-active'}`}
                    onClick={this.resetFileUpload}>
                    <span aria-label='cancel resume upload button' onClick={this.resetFileUpload}>&#10005;</span>
                  </button>
                </label>
              </div>
              <div className='nm-career-application-form__col'>
                <span className={`nm-career-application-form__file-input-label-text
                ${coverLetterUpload && 'is-active'}
                ${coverLetterUploadHasError && 'has-error'}`}>
                  {!coverLetterUploadHasError
                    ? coverLetterUploadLabel
                    : coverLetterUploadErrorMessage}
                </span>
                <label
                  className={`nm-career-application-form__file-input-label ${coverLetterUploadHasError && 'has-error'}`}>
                  <span
                    id='career-applicant-cover-letter-upload-button'
                    role='button'
                    aria-controls='career-applicant-cover-letter-upload'
                    tabIndex='0'>
                    {coverLetterUploadButtonLabel}
                  </span>
                  <input
                    id='career-applicant-cover-letter-upload'
                    className='nm-career-application-form__file-input'
                    name='coverLetterUpload'
                    type='file'
                    onChange={this.handleFileUpload} />
                  <button
                    id='cover-letter-reset-button'
                    type='button'
                    className={`nm-career-application-form__reset-button ${coverLetterUpload && 'is-active'}`}
                    onClick={this.resetFileUpload}>
                    <span aria-label='cancel cover letter upload button' onClick={this.resetFileUpload}>&#10005;</span>
                  </button>
                </label>
              </div>
              <label
                className={`nm-career-application-form__input-label ${linkedInUrlHasError && 'has-error'}`}>
                <span className='assistive-text'>{linkedInUrlLabel}</span>
                <span
                  className={`nm-career-application-form__input-label-text ${linkedInUrl && 'is-active'}`}
                  aria-hidden='true'>
                  {!linkedInUrlHasError
                    ? linkedInUrlLabel
                    : linkedInUrlErrorMessage}
                </span>
                <input
                  id='career-applicant-linkedin-url'
                  className='nm-career-application-form__input'
                  name='linkedInUrl'
                  placeholder={linkedInUrlPlaceholder}
                  type='text'
                  maxLength='100'
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  onBlur={this.handleInputChange} />
              </label>
            </div>
          </fieldset>
          <button
            id='nm-career-application-form-submit-button'
            className="nm-career-application-form__button nmx-button is-yellow"
            type="submit"
            name="submit"
            value="Apply">
            Apply
          </button>
        </form>
        <NMRecaptcha sitekey='<%=recaptchaInvisible%>' size='invisible' callback={this.recaptchaCallback} formLocation='career-application-form' />
      </div>
    );
  }
}
