export default function fixRecaptchaWindow() {
  let interval = null;
  let i = 0;
  function detectIframe() {
    function addClassToIframe() {
      try {
        const reCaptchaIframe = document.querySelector('iframe[title="recaptcha challenge"]');
        const reCaptchaOverlay = reCaptchaIframe.parentNode.parentNode;
        if (reCaptchaOverlay) {
          reCaptchaOverlay.setAttribute('id', 'nmx-recaptcha-challenge');
          clearInterval(interval);
        }
      } catch (error) {
        // previous attempt failed, but the recursive function will continue until it succeeds.
      }
      i += 1;
      // Stops the recursive function is the element cannot be found after 20 seconds.
      if (i > 20) {
        clearInterval(interval);
      }
    }
    addClassToIframe();
    interval = setInterval(addClassToIframe, 1000);
  }
  detectIframe();
}
