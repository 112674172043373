/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export default class AjaxForm {
  formElm;

  addedFields;

  constructor(formElm) {
    this.formElm = formElm;
    this.addedFields = {};
  }

  addField(name, value) {
    this.addedFields[name] = value;
    return this;
  }

  get method() {
    return this.formElm.getAttribute('method');
  }

  get action() {
    return this.formElm.getAttribute('action');
  }

  promiseSubmit(returnResponseObject = false) {
    return new Promise((resolve, reject) => {
      const formData = new FormData(this.formElm);
      const xhr = new XMLHttpRequest();
      for (const key in this.addedFields) {
        formData.append(key, this.addedFields[key]);
      }
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          return (
            returnResponseObject
              ? reject(xhr)
              : reject(xhr.responseText)
          );
        }
        return false;
      };

      xhr.onerror = () => reject(xhr.responseText);
      xhr.open(this.method, this.action, true);
      xhr.send(formData);
    });
  }
}
