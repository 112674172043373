/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from 'react';
import { isArray, isFunction } from '../utils/checking.util';
import GeocoderService from '../services/geocoder.service';
import { newRelicJSError } from '../analytics/new_relic_helper';

/*
NMLocationSearchInput

Properties:
value (optional) - The value to place in the input
placeholder (optional) - A placeholder to show in the input field
onSelect (optional) - Callback method to trigger when a suggestion is selected
onChange (optional) - Callback method to trigger when the input value changes
*/
export default class NMLocationSearchInput extends React.Component {
  $input;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      highlightedSuggestion: undefined,
      suggestions: [],
      suggestionsAreVisible: false,
    };
  }

  get shouldShowResults() {
    if (!this.state.suggestionsAreVisible) return false;
    if (this.state.suggestions.length <= 0) return false;
    return true;
  }

  componentWillReceiveProps = (nextProps) => {
    let { value } = this.state;

    if (this.props.value !== nextProps.value) {
      // eslint-disable-next-line prefer-destructuring
      value = nextProps.value;
    }

    if (value === this.state.value) {
      return;
    }

    this.setState({ value });
  }

  updateSuggestions = (googleSuggestions) => {
    // googleSuggestions is successfully returning an object
    if (!isArray(googleSuggestions)) {
      googleSuggestions = [];
    }

    const suggestions = [];

    googleSuggestions.forEach((suggestion) => {
      suggestions.push({
        address: suggestion.description,
        lat: null,
        lng: null,
      });
    });

    const highlightedSuggestion = suggestions[0];

    this.setState({
      suggestions,
      highlightedSuggestion,
    });
  }

  updateAutocomplete() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }

    if (!this.state.value) {
      return this.updateSuggestions();
    }

    this.searchTimeout = setTimeout(() => {
      this.searchTimeout = null;

      GeocoderService.autocompleteService.getPlacePredictions({
        types: ['geocode'], // Removes businesses from results
        componentRestrictions: { country: 'us' }, // restrict autocomplete to United States
        input: this.state.value,
        radius: this.props.radius || 0,
      }, this.updateSuggestions);
    }, 100);
    return false;
  }

  selectSuggestion(suggestion, event) {
    // add event persist because the synthetic events were nullified before event.preventDefault() was called
    event.persist();

    this.setState({ value: suggestion.address });

    this.updateAutocomplete();

    if (isFunction(this.props.onSelect)) {
      GeocoderService
        .promiseGeocode(suggestion.address)
        .then((location) => {
          if (location) {
            suggestion.lat = location.lat;
            suggestion.lng = location.lng;
            suggestion.addressTypes = location.types.join(',');
            return suggestion;
          }
          return {};
        })
        .catch((response) => {
          const error = `An error occurred while geocoding: ${response}`;
          console.error(error);
          newRelicJSError(error);
        })
        .then(() => {
          this.props.onSelect(suggestion, event);
        });
    }
  }

  suggestionIsHighlighted(suggestion) {
    return (this.state.highlightedSuggestion === suggestion);
  }

  handleInputChange = (event) => {
    const newValue = event.target.value;

    if (newValue === this.state.value) {
      return;
    }

    this.setState({ value: event.target.value }, this.updateAutocomplete.bind(this));

    if (isFunction(this.props.onChange)) {
      this.props.onChange({ address: event.target.value });
    }
  }

  handleInputKeyDown = (event) => {
    if (isFunction(this.props.onKeyDown)) {
      this.props.onKeyDown();
    }
    switch (event.which) {
    case 40: // DOWN
      if (this.shouldShowResults) {
        event.preventDefault();
        this.highlightNextSuggestion();
      }
      break;
    case 38: // UP
      event.preventDefault();
      this.highlightPreviousSuggestion();
      break;
    case 13: // ENTER
    case 9: // TAB
      // if nothing to select, search with the value of the input field
      if (this.state.highlightedSuggestion && event.target.value) {
        // only fire if value exists
        event.preventDefault();
        event.target.blur();
        this.selectSuggestion(this.state.highlightedSuggestion, event);
        // Meant to handle focus to next input on tab select when used on the career interest form
        try {
          const nextInputWorkStatus = document.getElementById('nm-career-interest-form-current-job-status');
          if (nextInputWorkStatus) {
            nextInputWorkStatus.focus();
          }
          const nextInputGradYear = document.getElementById('nm-career-interest-form-grad-year');
          if (nextInputGradYear) {
            nextInputGradYear.focus();
          }
        } catch (error) {
          console.error(error);
        }
      } else if (isFunction(this.props.onSelect)) {
        this.props.onSelect({ address: this.state.value }, event);
      }
      break;
    case 27: // ESC
      event.target.blur();
      break;
    default:
      // do nothing
      break;
    }
  }

  highlightNextSuggestion() {
    let index = Math.max(this.state.suggestions.indexOf(this.state.highlightedSuggestion), 0);

    index += 1;
    if (index >= this.state.suggestions.length) {
      index = 0;
    }

    this.setState({ highlightedSuggestion: this.state.suggestions[index] });
  }

  highlightPreviousSuggestion() {
    let index = Math.max(this.state.suggestions.indexOf(this.state.highlightedSuggestion), 0);

    index -= 1;
    if (index < 0) {
      index = Math.max(0, this.state.suggestions.length - 1);
    }

    this.setState({ highlightedSuggestion: this.state.suggestions[index] });
  }

  handleInputFocus = () => {
    this.updateAutocomplete();
    this.setState({ suggestionsAreVisible: true });

    if (isFunction(this.props.onFocus)) {
      this.props.onFocus();
    }
  }

  handleInputBlur = () => {
    setTimeout(() => {
      this.setState({ suggestionsAreVisible: false });
    }, 33);
  }

  stopEventBubbling = (event) => {
    event.preventDefault();
  }

  render() {
    const {
      hasDismissButton,
      dismissLocationInput,
      id,
      className,
      placeholder,
      required,
    } = this.props;
    const {
      value,
      suggestions,
    } = this.state;
    return (
      <div
        className='nm-location-search-input'
        role='presentation'>
        {hasDismissButton
          && <button
            aria-label='Close location search input'
            className='nm-location-search-input__close-button'
            onClick={dismissLocationInput}>
            &#10005;
          </button>}
        <input
          id={id}
          value={value}
          type='text'
          aria-label='Location'
          className={className}
          placeholder={placeholder}
          defaultValue={value}
          onChange={this.handleInputChange}
          onKeyDown={this.handleInputKeyDown}
          onKeyUp={this.handleInputChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          aria-required={required}
          autoComplete='off'
          aria-autocomplete='both'
          aria-owns='location-autocomplete-results'
          aria-expanded={this.shouldShowResults}
        />
        <ul
          id='location-autocomplete-results'
          className={`nm-location-search-input__list
          ${this.shouldShowResults
        ? 'is-visible'
        : ''}`}
          role='listbox'
          aria-hidden={!this.shouldShowResults}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className={this.suggestionIsHighlighted(suggestion)
                ? 'is-highlighted'
                : ''}
              onMouseDown={this.selectSuggestion.bind(this, suggestion)}
              role='option'
              aria-selected={this.suggestionIsHighlighted(suggestion)}
              tabIndex='-1'>
              {suggestion.address}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
