import React from 'react';

const buttonLabel = 'Learn more and apply';

export default class NMCareerTypeCallouts extends React.Component {
  navigateToTarget = (targetUrl) => {
    window.location.href = targetUrl;
  }

  render = () => (
    <div className='nm-career-type-callouts'>
      <div className='nm-career-type-callouts__row'>
        <div className='nm-career-type-callouts__col' id='nm-career-type-callouts-fr-careers-link-container'>
          <div className='nm-career-type-callouts__icon-container'>
            <img src='/assets/images/man-with-flag.svg' alt='' />
          </div>
          <h3 className='nm-career-type-callouts__header'>Financial representative careers</h3>
          <p className='nm-career-type-callouts__text'>Build a thriving career as an independent business owner with our comprehensive training, meaningful mentorship and continuous support.</p>
          <button
            id='nm-career-type-callouts-fr-careers-link'
            className='nmx-button nmx-button--secondary'
            type='button'
            role='link'
            onClick={() => { this.navigateToTarget('/financial-representative-careers/'); }}>
            {buttonLabel}
          </button>
        </div>
        <div className='nm-career-type-callouts__col' id='nm-career-type-callouts-fr-internships-link-container'>
          <div className='nm-career-type-callouts__icon-container'>
            <img src='/assets/images/rocket.svg' alt='' />
          </div>
          <h3 className='nm-career-type-callouts__header'>Financial representative internships</h3>
          <p className='nm-career-type-callouts__text'>Time to shine beyond the classroom. You&#39;ll learn the ins and outs of financial planning from seasoned experts in an award&#45;winning program.</p>
          <button
            id='nm-career-type-callouts-fr-internships-link'
            className='nmx-button nmx-button--secondary'
            type='button'
            role='link'
            onClick={() => { this.navigateToTarget('/internships/'); }}>
            {buttonLabel}
          </button>
        </div>
        <div className='nm-career-type-callouts__col' id='nm-career-type-callouts-corporate-careers-link-container'>
          <div className='nm-career-type-callouts__icon-container'>
            <img src='/assets/images/nametag.svg' alt='' />
          </div>
          <h3 className='nm-career-type-callouts__header'>
            <span className='nm-career-type-callouts__block'>Corporate</span>
            <span className='nm-career-type-callouts__block'>careers</span>
          </h3>
          <p className='nm-career-type-callouts__text'>Our corporate employees enjoy a stimulating culture in a growth&#45;oriented environment that fosters professional success and work&#45;life balance.</p>
          <button
            id='nm-career-type-callouts-corporate-careers-link'
            className='nmx-button nmx-button--secondary'
            type='button'
            role='link'
            onClick={() => { this.navigateToTarget('https://careers.northwesternmutual.com/'); }}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}
