import { isString } from './utils/checking.util';

export default class Session {
  static get(key) {
    if (!isString(key)) {
      throw new Error("'key' must be a string a value");
    }

    let value;

    if (window.sessionStorage) {
      value = window.sessionStorage[key];
    }

    return value;
  }

  static set(key, value) {
    if (!isString(key)) {
      throw new Error("'key' must be a string a value");
    }

    if (window.sessionStorage) {
      window.sessionStorage[key] = value;
    }

    return this;
  }

  static delete(key) {
    if (!isString(key)) {
      throw new Error("'key' must be a string a value");
    }

    if (window.sessionStorage) {
      delete window.sessionStorage[key];
    }

    return this;
  }
}
