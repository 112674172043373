import React from 'react';

export default class NMFormConfirmation extends React.Component {
  render() {
    const { success, header1, header2, header3, text1, text2, text3, text4 } = this.props;
    return (
      <div className="nm-form-confirmation">
        {success && <img className="nm-form-confirmation__image" src="/assets/images/success-icon.svg" alt="Person in Circle with checkmark icon" />}
        <h3 className="nm-form-confirmation__header">
          <span className="nm-form-confirmation__header-line">{header1}</span>
          <span className="nm-form-confirmation__header-line">{header2}</span>
          <span className="nm-form-confirmation__header-line">{header3}</span>
        </h3>
        <p className="nm-form-confirmation__text">
          <span className="nm-form-confirmation__text-line">{text1}</span>
          <span className="nm-form-confirmation__text-line">{text2}</span>
          <span className="nm-form-confirmation__text-line">{text3}</span>
          <span className="nm-form-confirmation__text-line">{text4}</span>
        </p>
      </div>
    );
  }
}
