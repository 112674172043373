// This is STEP 1 of the Career Interest Form
// Links to the Apply Now Personal Info Application Form on submit
import React, { useEffect, useState } from 'react';
import { directCall } from '@nmx/utils/dist/utilities/frontend/Analytics/dtm_helper';
import Session from '@nmx/utils/dist/utilities/frontend/session';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import GeocoderService from '../../services/geocoder.service';
import { isFunction } from '../../utils/checking.util';
import NMLocationSearchInput from '../nm_location_search_input.component';
import { newRelicJSError } from '../../analytics/new_relic_helper';

const errorLabel = 'Oops! Required field.';
const zipLocationLabel = 'City or Address';
const militaryStatusLabel = 'Military status';
const workStatusLabel = 'Work status';
const graduationYearLabel = 'Graduation year';
const foundUsViaLabel = 'Found us via';
const referralNameLabel = 'Referral name';
const years = [];
const intern = 'Intern';
const financialProfessional = 'Financial professional';
const advisorReferral = 'Advisor referral';
const approachedByRecruiter = 'Approached by a recruiter';
const familyFriendOrAcquaintance = 'Family, friend or acquaintance';
let militaryCandidate;
const userLocation = {
  address: '',
  lat: '',
  lng: '',
};

const NMCareerInterestForm = (props) => {
  const {
    careerinterest,
    onSubmit,
  } = props;

  // Setting up states
  // We need to maintain the underscore convention for the name inputs
  // to be consistent with office sites and so they're picked up by bullhorn.
  // The rest of the vars/states will be camelCase
  // input values
  const [values, setValues] = useState({
    test: 'Initial state',
    military_status: '',
    work_status: '',
    graduation_year: '',
    found_us_via: '',
    referral_name: '',
  });

  // Input display states
  const [inputs, setInputDisplay] = useState({
    displayMilitaryStatusInput: false,
    displayWorkStatusInput: true,
    displayGraduationYearInput: false,
    displayFoundUsViaInput: true,
    displayReferralNameInput: false,
  });

  // Input label display states
  const [labels, setLabelDisplay] = useState({
    displayZipLocationLabel: false,
    displayMilitaryStatusLabel: false,
    displayWorkStatusLabel: false,
    displayGraduationYearLabel: false,
    displayFoundUsViaLabel: false,
    displayReferralNameLabel: false,
  });

  // Zip location placeholder
  const [zipLocationPlaceHolder, setZipLocationPlaceHolder] = useState(zipLocationLabel);

  // error states
  const [errorStates, setErrorStates] = useState({
    zipLocationHasError: false,
    militaryStatusHasError: false,
    workStatusHasError: false,
    graduationYearHasError: false,
    foundUsViaHasError: false,
    referralNameHasError: false,
  });

  // If users are coming in from other domains with the query param military='true'
  // show them the military version of the form
  const checkForMilitaryParam = () => Location.getQueryParam('military');

  const setMilitaryCareersSession = () => {
    Session.set('militaryCareersCandidate', true);
  };

  useEffect(() => {
    const incomingMilitaryCandidate = checkForMilitaryParam();
    if (incomingMilitaryCandidate) {
      setMilitaryCareersSession();
    }
    const date = new Date();
    const currentYear = date.getFullYear();
    // If we are on the internships page, set militaryCareersCandidate session var to false
    if (window.location.href.indexOf('internships') > -1) {
      Session.set('militaryCareersCandidate', false);
    }

    militaryCandidate = Session.get('militaryCareersCandidate');

    // Creates dropdown list of graduation years. Current year plus 5.
    for (let i = 0; i < 6; i += 1) {
      years.push((currentYear + i).toString());
    }

    // check career interest for the page and display the appropriate inputs
    // The intern variation of this form should be isolated from
    // the military careers flow changes.
    if (militaryCandidate === 'true') {
      setInputDisplay({
        ...inputs,
        displayMilitaryStatusInput: true,
        displayWorkStatusInput: false,
        displayGraduationYearInput: false,
        displayFoundUsViaInput: false,
      });
    } else if (careerinterest === intern) {
      setInputDisplay({
        ...inputs,
        displayMilitaryStatusInput: false,
        displayWorkStatusInput: false,
        displayGraduationYearInput: true,
        displayFoundUsViaInput: true,
      });
    }
  }, []);

  const showReferralInput = () => {
    setInputDisplay({
      ...inputs,
      displayReferralNameInput: true,
    });
  };

  const hideReferralInput = () => {
    setInputDisplay({
      ...inputs,
      displayReferralNameInput: false,
    });
  };

  const showLocationLabel = () => {
    setLabelDisplay({
      ...labels,
      displayZipLocationLabel: true,
    });
    setZipLocationPlaceHolder('');
  };

  const showSelectBoxLabel = (event) => {
    switch (event.target.name) {
    case 'military_status':
      setLabelDisplay({
        ...labels,
        displayMilitaryStatusLabel: true,
      });
      setErrorStates({
        ...errorStates,
        militaryStatusHasError: false,
      });
      break;
    case 'work_status':
      setLabelDisplay({
        ...labels,
        displayWorkStatusLabel: true,
      });
      setErrorStates({
        ...errorStates,
        workStatusHasError: false,
      });
      break;
    case 'graduation_year':
      setLabelDisplay({
        ...labels,
        displayGraduationYearLabel: true,
      });
      setErrorStates({
        ...errorStates,
        graduationYearHasError: false,
      });
      break;
    case 'found_us_via':
      setLabelDisplay({
        ...labels,
        displayFoundUsViaLabel: true,
      });
      setErrorStates({
        ...errorStates,
        foundUsViaHasError: false,
      });
      break;
    default:
        // do nothing
    }
  };

  const checkIfReferralIsNeeded = (val) => {
    if (
      val === advisorReferral
      || val === approachedByRecruiter
      || val === familyFriendOrAcquaintance
    ) {
      showReferralInput();
    } else {
      hideReferralInput();
    }
  };

  const handleInputChange = (event) => {
    setValues({
      ...values,
      test: 'New State',
      [event.target.name]: event.target.value,
    });

    showSelectBoxLabel(event); // This is overwriting things and setting them back to initial state
    // If user selected from the how did you find us box,
    // determine if we need to display the referral input
    if (event.target.name === 'found_us_via') {
      checkIfReferralIsNeeded(event.target.value);
    }
    if (event.target.name === 'referral_name') {
      setLabelDisplay({
        ...labels,
        displayReferralNameLabel: true,
      });
      setErrorStates({
        ...errorStates,
        referralNameHasError: false,
      });
    }
  };

  const clearLocationErrors = (loc) => {
    if (loc.address) {
      setLabelDisplay({
        ...labels,
        displayZipLocationLabel: true,
      });
      setErrorStates({
        ...errorStates,
        zipLocationHasError: false,
      });
    }
  };

  const setLocation = (loc) => {
    if (loc.address) {
      userLocation.address = loc.address || '';
      userLocation.lat = loc.lat || '';
      userLocation.lng = loc.lng || '';
      clearLocationErrors(loc);
    }
  };

  // as they type in the location input field set the location properties in case
  // they click the search button without picking an option in the dropdown.
  const handleOnChangeLocation = (loc) => {
    if (!loc.address) {
      setLocation(loc);
    }
    showLocationLabel();
  };

  const trackAnalyticsFill = (actionType, actionValue) => {
    directCall('shared-call', {
      'action-type': actionType,
      'action-keys': 'nm-career-interest-form',
      'action-values': actionValue,
    });
  };

  const handleAnalyticsCall = (event) => {
    if (event.target.value) {
      const { value, name } = event.target;
      trackAnalyticsFill(name, value);
    }
  };

  const handleLocationAnalytics = (loc) => {
    if (loc.address) {
      setLocation(loc);
      trackAnalyticsFill('location', loc.address);
    }
  };

  const getLatitudeAndLongitudeFromAddress = () => new Promise((resolve) => {
    if (userLocation && userLocation.address && !userLocation.lat && !userLocation.lng) {
      GeocoderService
        .promiseGeocode(userLocation.address).then((geocodedLocation) => {
          if (geocodedLocation) {
            // eslint-disable-next-line no-param-reassign
            geocodedLocation.address = userLocation.address;
            setLocation(geocodedLocation);
          } else {
            setLocation(userLocation); // failed to get lat lng, update location accordingly
          }
          resolve();
        }).catch(() => {
          const error = `Error getting reps with location: ${userLocation.address}.`;
          console.error(error);
          newRelicJSError(error);
          setLocation(userLocation); // failed to get lat lng, update location accordingly
          resolve();
        });
    } else {
      resolve();
    }
  });

  // Loops through the errorCheck object to see if any validators returned any errors
  const errorCheckLoop = (errField) => {
    let res = false;
    Object.keys(errField).forEach((key) => {
      const value = errField[key];
      if (value === true) {
        res = true;
      }
    });
    return res;
  };

  const validateZipLocation = (loc) => {
    if (!loc || loc === '') {
      // If zip code/location input has error, show placeholder again
      setZipLocationPlaceHolder(zipLocationLabel);
      return true;
    }
    return false;
  };

  const validateMilitaryStatus = () => {
    if (militaryCandidate === 'true' && !values.military_status) {
      // military_status is required and is empty
      return true;
    }
    return false;
  };

  const validateWorkStatus = () => {
    if (careerinterest === financialProfessional
      && !values.work_status
      && (militaryCandidate === 'false' || !militaryCandidate)) {
      // work_status is required and is empty
      return true;
    }
    return false;
  };

  const validateGraduationYear = () => {
    if (careerinterest === intern
      && !values.graduation_year
      && (militaryCandidate === 'false' || !militaryCandidate)) {
      // graduation_year is required and is empty
      return true;
    }
    return false;
  };

  const validateFoundUsVia = () => {
    if (!values.found_us_via && (militaryCandidate === 'false' || !militaryCandidate)) {
      // found_us_via is required (for non-military candidates) and is empty
      return true;
    }
    return false;
  };

  const validateReferralName = (referralName) => {
    const trimmedString = referralName.replace(/\s/g, '');
    if (inputs.displayReferralNameInput && (!referralName || trimmedString === '')) {
      // referral_name is displaying and required, and is empty
      return true;
    }
    return false;
  };

  const submitForm = () => {
    getLatitudeAndLongitudeFromAddress().then(() => {
      // If they've passed in an onSubmit callback, utilize that
      if (isFunction(onSubmit)) {
        onSubmit(this.name, userLocation);
      } else {
        // Otheriwse do URL manipulation
        const params = [];
        if (userLocation.address) {
          params.push(`address=${escape(userLocation.address)}`);
        }

        if (userLocation.lat) {
          params.push(`lat=${escape(userLocation.lat)}`);
        }

        if (userLocation.lng) {
          params.push(`lng=${escape(userLocation.lng)}`);
        }

        if (values.military_status) {
          params.push(`military_status=${escape(values.military_status)}`);
        }

        if (militaryCandidate === 'true') {
          params.push(`military_candidate=${escape(militaryCandidate)}`);
        }

        if (values.work_status) {
          params.push(`work_status=${escape(values.work_status)}`);
        }

        if (values.graduation_year) {
          params.push(`graduation_year=${escape(values.graduation_year)}`);
        }

        if (values.found_us_via) {
          params.push(`found_us_via=${escape(values.found_us_via)}`);
        }

        if (values.referral_name) {
          params.push(`referral_name=${escape(values.referral_name)}`);
        }

        if (careerinterest) {
          params.push(`career_interest=${escape(careerinterest)}`);
        }

        let target = props.target || '';
        target += target
          .indexOf('?') > -1
          ? '&'
          : '?';
        target += params.join('&');

        window.location.href = target;
      }
    }).catch((err) => {
      const error = `Unable to complete search: ${err}`
        ? err.error || err.message
        : '';
      newRelicJSError(error);
      console.error(error);
    });
  };

  const validateInputs = () => {
    // setting up error object to pass into the setter
    const errorCheck = {
      zipLocationHasError: validateZipLocation(userLocation.address),
      militaryStatusHasError: validateMilitaryStatus(), // conditional
      workStatusHasError: validateWorkStatus(), // conditional
      graduationYearHasError: validateGraduationYear(), // conditional
      foundUsViaHasError: validateFoundUsVia(),
      referralNameHasError: validateReferralName(values.referral_name), // conditional
    };

    // Set error states based on the validated errorCheck object
    setErrorStates(errorCheck);

    // Loop through error states and see if any return true (have errors).
    const formHasErrors = errorCheckLoop(errorCheck);
    if (!formHasErrors) {
      submitForm();
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    validateInputs();
  };

  return (
    <section className="nm-career-interest-form" id='section-nm-career-interest-form'>
      <div className="nm-career-interest-form__intro">
        <div className="nm-career-interest-form__intro-icon-container">
          <img
            className='nm-career-interest-form__intro-icon'
            src={`${careerinterest === intern
              ? '/assets/images/career-interest-form/rocket-ship.svg'
              : '/assets/images/career-interest-form/person-with-flag.svg'}`}
            alt='' />
        </div>
        <h2 className="nm-career-interest-form__header">
          <span className="nm-career-interest-form__header-block">Be part of our team.&nbsp;</span>
          <span className="nm-career-interest-form__header-block">Apply now.</span>
        </h2>
        <p className="nm-career-interest-form__text">Start with the basics, choose your ideal location, then give us a few details about yourself.</p>
      </div>
      <form
        id='career-interest-form-module'
        className="nm-career-interest-form__form"
        method="get"
        onSubmit={validateForm}>
        <div className='nm-career-interest-form__intro-arrow' />
        <fieldset className="nm-career-interest-form__form-group">
          <h3 className="nm-career-interest-form__form-group-header">
            <span className="nm-career-interest-form__form-group-header--bold">Step 1</span> of 3
          </h3>
          <div className="nm-career-interest-form__row">
            {/* ZIP Code or Location //////////////////////////////////////////// */}
            <div className="nm-career-interest-form__col">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="nm-career-interest-form__label">
                <span
                  className={`nm-career-interest-form__label-text
                  ${labels.displayZipLocationLabel && 'nm-career-interest-form__label-text--is-showing'}
                  ${errorStates.zipLocationHasError && 'nm-career-interest-form__label-text--has-error'}`}>
                  {errorStates.zipLocationHasError
                    ? errorLabel
                    : zipLocationLabel}
                </span>
                <NMLocationSearchInput
                  id='nm-search-directory-form-location-input'
                  className={`nm-career-interest-form__input ${errorStates.zipLocationHasError && 'has-error-border'}`}
                  type='text'
                  placeholder={zipLocationPlaceHolder}
                  value={userLocation.address}
                  onChange={handleOnChangeLocation}
                  onSelect={handleLocationAnalytics}
                  required='true'
                />
              </label>
            </div>
            {/* Military Status //////////////////////////////////////////// */}
            <div
              className={`nm-career-interest-form__col ${
                inputs.displayMilitaryStatusInput
                  ? ''
                  : 'hide'}`}>
              <label
                className="nm-career-interest-form__label nm-career-interest-form__label--for-dropdown">
                <span
                  className={`nm-career-interest-form__label-text
                  ${labels.displayMilitaryStatusLabel && 'nm-career-interest-form__label-text--is-showing'}
                  ${errorStates.militaryStatusHasError && 'nm-career-interest-form__label-text--has-error'}`}>
                  {errorStates.militaryStatusHasError
                    ? errorLabel
                    : militaryStatusLabel}
                </span>
                <select
                  className={`nm-career-interest-form__input nm-career-interest-form__input--is-dropdown ${
                    errorStates.militaryStatusHasError
                    && 'nm-career-interest-form__input--has-error-border'
                  }`}
                  name="military_status"
                  id="nm-career-interest-form-current-military-status"
                  value={values.military_status}
                  onInput={handleAnalyticsCall}
                  onBlur={handleInputChange}
                  onChange={handleInputChange}
                  aria-required="true">
                  <option className='nm-career-interest-form__option' value="" disabled hidden>
                    {militaryStatusLabel}
                  </option>
                  <option className='nm-career-interest-form__option' value='Veteran'>Veteran</option>
                  <option className='nm-career-interest-form__option' value='Active Military'>Active Military</option>
                  <option className='nm-career-interest-form__option' value='Military Reserves'>Military Reserves</option>
                  <option className='nm-career-interest-form__option' value="In between jobs">In between jobs</option>
                </select>
              </label>
            </div>
            {/* Work Status //////////////////////////////////////////// */}
            <div
              className={`nm-career-interest-form__col ${
                inputs.displayWorkStatusInput
                  ? ''
                  : 'hide'}`}>
              <label
                className="nm-career-interest-form__label nm-career-interest-form__label--for-dropdown">
                <span
                  className={`nm-career-interest-form__label-text
                  ${labels.displayWorkStatusLabel && 'nm-career-interest-form__label-text--is-showing'}
                  ${errorStates.workStatusHasError && 'nm-career-interest-form__label-text--has-error'}`}>
                  {errorStates.workStatusHasError
                    ? errorLabel
                    : workStatusLabel}
                </span>
                <select
                  className={`nm-career-interest-form__input nm-career-interest-form__input--is-dropdown ${
                    errorStates.workStatusHasError
                    && 'nm-career-interest-form__input--has-error-border'
                  }`}
                  name="work_status"
                  id="nm-career-interest-form-current-job-status"
                  value={values.work_status}
                  onInput={handleAnalyticsCall}
                  onBlur={handleInputChange}
                  onChange={handleInputChange}
                  aria-required="true">
                  <option className='nm-career-interest-form__option' value="" disabled hidden>
                    {workStatusLabel}
                  </option>
                  <option className='nm-career-interest-form__option' value="Employed, similar field">Employed, similar field</option>
                  <option className='nm-career-interest-form__option' value="Employed, want to change careers">Employed, want to change careers</option>
                  <option className='nm-career-interest-form__option' value="Recent college graduate">Recent college graduate</option>
                  <option className='nm-career-interest-form__option' value="In between jobs">In between jobs</option>
                </select>
              </label>
            </div>
            {/* Graduation Year //////////////////////////////////////////// */}
            <div className={`nm-career-interest-form__col ${
              inputs.displayGraduationYearInput
                ? ''
                : 'hide'}`}>
              <label
                className="nm-career-interest-form__label nm-career-interest-form__label--for-dropdown">
                <span
                  className={`nm-career-interest-form__label-text
                  ${labels.displayGraduationYearLabel && 'nm-career-interest-form__label-text--is-showing'}
                  ${errorStates.graduationYearHasError && 'nm-career-interest-form__label-text--has-error'}`}>
                  {errorStates.graduationYearHasError
                    ? errorLabel
                    : graduationYearLabel}
                </span>
                <select
                  className={`nm-career-interest-form__input nm-career-interest-form__input--is-dropdown ${
                    errorStates.graduationYearHasError
                    && 'nm-career-interest-form__input--has-error-border'
                  }`}
                  name="graduation_year"
                  id="nm-career-interest-form-grad-year"
                  value={values.graduation_year}
                  onInput={handleAnalyticsCall}
                  onBlur={handleInputChange}
                  onChange={handleInputChange}
                  aria-required="true">
                  <option className='nm-career-interest-form__option' value="" disabled hidden>
                    {graduationYearLabel}
                  </option>
                  {years.map((year, index) => (
                    <option key={index} className='nm-career-interest-form__option' value={year}>{year}</option>
                  ))}
                </select>
              </label>
            </div>
            {/* How did you find us //////////////////////////////////////////// */}
            <div className={`nm-career-interest-form__col ${
              inputs.displayFoundUsViaInput
                ? ''
                : 'hide'}`}>
              <label
                className="nm-career-interest-form__label nm-career-interest-form__label--for-dropdown">
                <span
                  className={`nm-career-interest-form__label-text
                  ${labels.displayFoundUsViaLabel && 'nm-career-interest-form__label-text--is-showing'}
                  ${errorStates.foundUsViaHasError && 'nm-career-interest-form__label-text--has-error'}`}>
                  {errorStates.foundUsViaHasError
                    ? errorLabel
                    : foundUsViaLabel}
                </span>
                <select
                  className={`nm-career-interest-form__input nm-career-interest-form__input--is-dropdown ${
                    errorStates.foundUsViaHasError
                    && 'nm-career-interest-form__input--has-error-border'
                  }`}
                  name="found_us_via"
                  id="nm-career-interest-form-how-did-you-find-us"
                  value={values.found_us_via}
                  onInput={handleAnalyticsCall}
                  onBlur={handleInputChange}
                  onChange={handleInputChange}
                  aria-required="true">
                  <option className='nm-career-interest-form__option' value="" disabled hidden>
                    {foundUsViaLabel}
                  </option>
                  <option className='nm-career-interest-form__option' value={advisorReferral}>{advisorReferral}</option>
                  <option className='nm-career-interest-form__option' value={approachedByRecruiter}>{approachedByRecruiter}</option>
                  <option className='nm-career-interest-form__option' value={familyFriendOrAcquaintance}>{familyFriendOrAcquaintance}</option>
                  <option className='nm-career-interest-form__option' value="Professional career fair">Professional career fair</option>
                  <option className='nm-career-interest-form__option' value="Indeed">Indeed</option>
                  <option className='nm-career-interest-form__option' value="Glassdoor">Glassdoor</option>
                  <option className='nm-career-interest-form__option' value="LinkedIn">LinkedIn</option>
                  <option className='nm-career-interest-form__option' value="Northwestern Mutual website">Northwestern Mutual website</option>
                  <option className='nm-career-interest-form__option' value="Other job board">Other job board</option>
                </select>
              </label>
            </div>
            {/* Referral //////////////////////////////////////////// */}
            <div className={`nm-career-interest-form__col ${
              inputs.displayReferralNameInput
                ? ''
                : 'hide'}`}>
              <label
                className="nm-career-interest-form__label">
                <span
                  className={`nm-career-interest-form__label-text
                  ${labels.displayReferralNameLabel && 'nm-career-interest-form__label-text--is-showing'}
                  ${errorStates.referralNameHasError && 'nm-career-interest-form__label-text--has-error'}`}>
                  {errorStates.referralNameHasError
                    ? errorLabel
                    : referralNameLabel}
                </span>
                <input
                  className={`nm-career-interest-form__input ${
                    errorStates.referralNameHasError
                    && 'nm-career-interest-form__input--has-error-border'
                  }`}
                  name="referral_name"
                  id="nm-career-interest-form-referral-name"
                  type='text'
                  value={values.referral_name}
                  onBlur={handleAnalyticsCall}
                  onChange={handleInputChange}
                  onKeyDown={handleInputChange}
                  placeholder={referralNameLabel}
                  aria-required="true" />
              </label>
            </div>
            <div className="nm-career-interest-form__col">
              <span className='nm-career-interest-form__label-text' />
              <button
                id='nm-career-interest-form-submit-button'
                className="nm-career-interest-form__button nmx-button is-yellow"
                type="submit">
                Continue
              </button>
            </div>
          </div>

        </fieldset>
      </form>
    </section>
  );
};

export default NMCareerInterestForm;
