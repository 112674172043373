import Location from '@nmx/utils/dist/utilities/frontend/location';
import Session from '../session';

if (Location.hasQueryParam('utm_source')) {
  Session.set('utmSource', Location.getQueryParam('utm_source'));
}

if (Location.hasQueryParam('utm_medium')) {
  Session.set('utmMedium', Location.getQueryParam('utm_medium'));
}

if (Location.hasQueryParam('utm_campaign')) {
  Session.set('utmCampaign', Location.getQueryParam('utm_campaign'));
}

if (Location.hasQueryParam('utm_content')) {
  Session.set('utmContent', Location.getQueryParam('utm_content'));
}

if (Location.hasQueryParam('utm_term')) {
  Session.set('utmTerm', Location.getQueryParam('utm_term'));
}
