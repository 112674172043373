let geocoder;
let autocompleteService;

export default class GeocoderService {
  static get geocoder() {
    if (typeof google === 'undefined') {
      throw new Error('google service has not been included and is required for this component to operate');
    }

    geocoder = geocoder || new window.google.maps.Geocoder();

    return geocoder;
  }

  static get autocompleteService() {
    if (typeof google === 'undefined') {
      throw new Error('google service has not been included and is required for this component to operate');
    }

    autocompleteService = autocompleteService || new window.google.maps.places.AutocompleteService();

    return autocompleteService;
  }

  static promiseGeocode(address) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          return resolve({
            address,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            types: results[0].types,
          });
        }
        return reject(new Error(`No geocoding results for: ${address}`));
      });
    });
  }

  static promiseReverseGeocode(location) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({
        location: {
          lat: location.lat,
          lng: location.lng,
        },
      }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          return resolve({
            address: results[0].geometry.location.formatted_address(),
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        }
        return reject(results);
      });
    });
  }
}
