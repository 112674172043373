/* eslint-disable no-prototype-builtins */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import { Theme } from '@nmx/template/dist/components';

export default function (tagName, componentDefinition, withTheme) {
  Array.prototype.slice.call(document.getElementsByTagName(tagName)).forEach((elm) => {
    const props = {};

    for (let index = 0; index < elm.attributes.length; index += 1) {
      const attribute = elm.attributes[index];
      props[attribute.name] = attribute.value;
    }

    if (props.hasOwnProperty('container')) {
      throw new Error(`'container' cannot be use as an attribute for ${elm.tagName}`);
    }

    if (props.hasOwnProperty('children')) {
      throw new Error(`'children' cannot be use as an attribute for ${elm.tagName}`);
    }

    props.container = elm;
    props.children = Array.prototype.slice.call(elm.childNodes, 0).map(child => child.cloneNode(true));

    if (withTheme) {
      ReactDOM.render(<ThemeProvider theme={Theme.render(typeof window !== 'undefined' ? window.innerWidth : 480, typeof window !== 'undefined' ? window.innerHeight : 900)}>{React.createElement(componentDefinition, props)}</ThemeProvider>, elm);
    } else {
      ReactDOM.render(React.createElement(componentDefinition, props), elm);
    }
  });
}
