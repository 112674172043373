/* eslint-disable no-loop-func */
import React from 'react';

let allMarkers = [];
const starIcon = '/assets/images/google-maps/star-map-marker-v2.svg';
const defaultLabelColor = '#fff';
const defaultLabelFontWeight = 'bold';
let map;
let bounds;

export default class NMDynamicGoogleMap extends React.Component {
  resetMapMarkers = () => {
    for (let x = 0; x < allMarkers.length; x += 1) {
      allMarkers[x].setIcon(null);
      allMarkers[x].label = {
        text: ((x + 1).toString()),
        color: defaultLabelColor,
        fontWeight: defaultLabelFontWeight,
      };
    }
  }

  setSelectedMarker = (mapMarker) => {
    const pin = mapMarker;
    pin.setIcon(starIcon);
    pin.label = '';
    map.setCenter(pin.getPosition());
    // Uncomment this if you want the map to zoom and fit bounds with every click
    // if(bounds){
    //   map.fitBounds(bounds)
    // }
  }

  // Initialize and add the map
  initMap = () => {
    allMarkers = [];
    bounds = new window.google.maps.LatLngBounds();
    map = new window.google.maps.Map(
      document.getElementById('dynamic-google-map'), {
        zoom: 16,
        streetViewControl: false,
        disableDefaultUI: true,
      }
    );

    if (this.props.data.length > 0) {
      // Create all map markers
      for (let i = 0; i < this.props.data.length; i += 1) {
        const y = i + 1;
        const markerId = `office-location-input-${y}`;
        const markerLabel = ((y).toString());
        const officeLocation = {
          lat: (this.props.data[i].location.lat),
          lng: (this.props.data[i].location.lon),
        };
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: officeLocation,
          map,
          label: {
            text: markerLabel,
            color: defaultLabelColor,
            fontWeight: defaultLabelFontWeight,
            className: 'gm-marker-label',
          },
        });
        // Set map zoom to encompass all location markers
        bounds.extend(officeLocation);
        // Add marker to markers array
        allMarkers.push(marker);
        marker.addListener('click', () => {
          this.resetMapMarkers();
          // Set input IDs
          this.props.mapselectcallback(markerId);
          this.setSelectedMarker(marker);
        });
      }
      // Sets the map zoom to show all map markers
      map.fitBounds(bounds);
      // maps has fully loaded
      const idleListener = window.google.maps.event.addListener(map, 'idle', () => {
        if (map.getZoom() > 11) map.setZoom(11);
        window.google.maps.event.removeListener(idleListener);
      });

      const tilesLoadedListener = window.google.maps.event.addListener(map, 'tilesloaded', () => {
        const gmMarkerLabels = document.body.querySelectorAll('.gm-marker-label');
        gmMarkerLabels.forEach(
          (currentValue, currentIndex, listObj) => {
            // eslint-disable-next-line no-param-reassign
            listObj[currentIndex].parentNode.parentNode.parentNode.style.transform = 'none';
          }
        );
        window.google.maps.event.removeListener(tilesLoadedListener);
      });
    }
  }

  createInputEventListeners = () => {
    const inputs = document.querySelectorAll(this.props.inputclass);
    for (let i = 0; i < inputs.length; i += 1) {
      inputs[i].addEventListener('click', () => {
        this.resetMapMarkers();
        this.setSelectedMarker(allMarkers[i]);
      });
    }
  }

  componentDidMount = () => {
    this.initMap();
    this.createInputEventListeners();
    const mapButton = document.getElementById('nm-career-office-locator-form-map-control-button');
    mapButton.addEventListener('click', () => {
      map.fitBounds(bounds);
    });
  }

  render = () => (
    <div className='nm-dynamic-google-map'>
      <div
        id='dynamic-google-map'
        className='nm-dynamic-google-map__map'
      />
    </div>
  )
}
