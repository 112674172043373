/* eslint-disable import/first */
// Import polyfills
import 'es6-promise/auto';
import 'es6-object-assign/auto';
// Grab agent information from the url and store them in the session so they can be utilized later for tracking purposes
import './session/agent_information';
// Grab UTM codes from the url and store them in the session so they can be utilized later for tracking purposes
import './session/utm';
import LazyLoad from 'vanilla-lazyload';
// Start Registering Components
import registerComponent from './register_component';
// Helpers
import './helpers/recaptchaPositioning';
// Import specific components to expose
import './components/nm_agent_form_recaptcha.component'; // Secondary
import './components/nm_recaptcha.component'; // Secondary
import NMCareerInterestFormComponent from './components/forms/NMCareerInterestForm';
import NMCareerOfficeLocatorForm from './components/forms/nm-career-office-locator-form.component';
import NMCareerTypeCallouts from './components/careers/nm-career-type-callouts.component';
import NMScrollToAnchorButton from './components/nm-scroll-to-anchor-button.component';
import NMStaticGoogleMapPin from './components/nm-static-google-map-pin.component';

registerComponent('nm-career-interest-form', NMCareerInterestFormComponent);
registerComponent('nm-career-office-locator-form', NMCareerOfficeLocatorForm);
registerComponent('nm-career-type-callouts', NMCareerTypeCallouts);
registerComponent('nm-scroll-to-anchor-button', NMScrollToAnchorButton);
registerComponent('nm-static-google-map-pin', NMStaticGoogleMapPin);

// Attach window resizing events to handling zooming properly
let timeout = null;
const onResize = () => {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    timeout = null;
    const ratio = window.outerWidth / window.innerWidth;
    if (ratio === 1) {
      window.document.body.style.zoom = null;
    } else {
      window.document.body.style.zoom = ratio;
    }
  }, 300);
};
window.addEventListener('resize', onResize);
onResize();

// eslint-disable-next-line no-unused-vars
const lazyload = new LazyLoad({ elements_selector: '.lazy' });
