import React from 'react';
import { newRelicJSError } from '../analytics/new_relic_helper';
import fixRecaptchaWindow from '../helpers/recaptchaPositioning';

export default class NMAgentFormRecaptcha extends React.Component {
    agentElement;

    recaptchaComponentId;

    constructor(props) {
      super(props);

      this.recaptchaComponentId = `${this.props.recaptchaIdName}-${Date.now().toString()}`;
    }

    componentDidMount() {
      // if we have a recaptcha id for this form, we need to reset it with that id
      // eslint-disable-next-line eqeqeq
      if (this.getRecaptchaId() || this.getRecaptchaId == 0) {
        window.grecaptcha.reset(this.getRecaptchaId());
      } else {
        this.renderRecaptcha();
      }
    }

    getRecaptchaId() {
      return this.props.recaptchaIds[this.props.recaptchaIdName];
    }

    setRecaptchaId(recaptchaId) {
      this.props.recaptchaIds[this.props.recaptchaIdName] = recaptchaId.toString();
    }

    reset = () => window.grecaptcha.reset(this.getRecaptchaId());

    execute = () => window.grecaptcha.execute(this.getRecaptchaId());

    renderRecaptcha() {
      try {
        if (window.grecaptcha && window.grecaptcha.render) {
          const recaptchaId = window.grecaptcha.render(document.getElementById(this.recaptchaComponentId), {
            sitekey: this.props.sitekey,
            size: this.props.size,
            callback: this.props.callback,
          });
          this.setRecaptchaId(recaptchaId);
          fixRecaptchaWindow();
        } else {
          setTimeout(this.renderRecaptcha.bind(this), 50);
        }
      } catch (error) {
        newRelicJSError(`Error rendering recaptcha on ${this.props.formLocation}.`, error);
      }
    }

    render() {
      return (
        <div id={this.recaptchaComponentId} aria-hidden='true' />
      );
    }
}
